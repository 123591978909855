export const FETCH_DATA_PENDING = 'FETCH_DATA_PENDING';
export const FETCH_DATA_SUCCESS = 'FETCH_DATA_SUCCESS';
export const FETCH_DATA_ERROR = 'FETCH_DATA_ERROR';
export const SET_GENDER = 'SET_GENDER';
export const GO_BACK = 'GO_BACK';
export const SELECT_GENDER = 'SELECT_GENDER';
export const QUIZ = 'QUIZ';
export const RESULTS = 'RESULTS';
export const SUBMIT_ANSWER = 'SUBMIT_ANSWER';
export const SUBMIT_EMAIL= 'SUBMIT_EMAIL';
