import React from "react";
import {connect} from "react-redux";
import {Container, Row, Nav, Navbar, ProgressBar, Form, Col} from "react-bootstrap";
import {getData} from "../reducers";
import {goBack, submitAnswer, submitEmail} from "../actions";
import {DFPSlotsProvider, DFPManager} from "react-dfp";
import {renderAd} from "./";


class Quiz extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            answers: [],
            email: null
        };

        this.handleAnswerSubmit = this.handleAnswerSubmit.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handleEmailSubmit = this.handleEmailSubmit.bind(this);
    }

    handleAnswerSubmit(e) {
        this.props.submitAnswer(this.state.answers)
        this.setState({answers: []})
        e.preventDefault();
        window.location.reload();
    }

    handleEmailChange(e) {
        this.setState({email: e.target.value});
    }

    handleEmailSubmit(e) {
        let data = {
            'domain': window.location.href.split("/")[2],
            'email': this.state.email,
            'name': 'quiz'
        };
        fetch('//leads.foreshop.net/v1/leads', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify(data)
        }).then((response) => {
            return response.json();
        })
        this.props.submitEmail(this.state.email)
        e.preventDefault();
    }

    handleSingleOptionClick(event) {
        return this.setState({answers: [event.currentTarget.innerText]})
    }

    handleMultipleOptionClick(event) {
        return !this.state.answers.includes(event.currentTarget.innerText)
            ? this.setState({answers: this.state.answers.concat(event.currentTarget.innerText)})
            : this.setState({answers: this.state.answers.filter(e => e !== event.currentTarget.innerText)})
    }

    renderQuestions(question) {
        return (
            <>
                <h3 className="px-4 pb-2 pt-4">{question.question}</h3>
                <form className="px-2" onSubmit={this.handleAnswerSubmit}>
                    {
                        question.a.map((e, i) => {
                            return (
                                <div
                                    className={this.state.answers.includes(e) ? "selected" : ""}
                                    key={e + i}
                                    onClick={event => question.t === "single" ? this.handleSingleOptionClick(event) : this.handleMultipleOptionClick(event)}
                                >
                                    {e}
                                </div>
                            )
                        })
                    }
                    <button type="submit" disabled={this.state.answers.length === 0}>Next</button>
                </form>
            </>
        );
    }

    renderEmailSubmit() {
        if (!this.state.buttonClicked) {
            return (
                <div className="px-4 my-5 py-5">
                    <button type="submit" onClick={() => this.setState({buttonClicked: true})}>
                        Click Here To View Your Quiz Results
                    </button>
                </div>
            );
        } else {
            return (
                <form className="mx-4" onSubmit={this.handleEmailSubmit}>
                    <label className="h3 my-4">Leave your email and discover<br/>your results!</label>
                    <Form.Control
                        type="email" required
                        onChange={this.handleEmailChange}
                        placeholder="enter your email..."
                        className="text-center border-0"
                    />
                    <button type="submit">Discover my results</button>
                </form>
            )
        }
    }

    componentDidMount() {
        this.interval = window.setInterval(
            () => { DFPManager.refresh(); },
            35000,
          );
      }

    render() {
        const {logo, color, questions, currentQuestion} = this.props.data;
        const progress = currentQuestion < questions.length ? (currentQuestion + 1) * 100 / (questions.length + 1) : 100;

        return (
            <DFPSlotsProvider dfpNetworkId="21863165165">
                <Container>
                    <Row>
                        {renderAd("leftAd")}
                        <Col className="quiz" xs={12} md={6} lg={4}>
                            <style>{`
                                :root {
                                  --main-color: ${color};
                                }
                            `}</style>
                            <Navbar bg="light">
                                <Nav.Link onClick={this.props.goBack}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 20" width="12" height="20">
                                        <polygon fill={color} points="8,0 12,2 6,10 12,18 8,20 0,10"/>
                                    </svg>
                                </Nav.Link>
                                <Navbar.Brand href="#home">
                                    <img src={logo} alt="" className="ml-2" width="150px"/>
                                </Navbar.Brand>
                            </Navbar>
                            <ProgressBar now={progress} striped animated variant="custom"/>
                            {currentQuestion < questions.length ? this.renderQuestions(questions[currentQuestion]) : this.renderEmailSubmit()}
                            <p className="px-4 my-5 text-center small">2020 © ALL RIGHTS RESERVED</p>
                        </Col>
                        {renderAd("rightAd")}
                    </Row>;
                </Container>
            </DFPSlotsProvider>
        )
    }
}

const mapStateToProps = state => ({
    data: getData(state)
})

function mapDispatchToProps(dispatch) {
    return {
        goBack: () => dispatch(goBack()),
        submitAnswer: answer => dispatch(submitAnswer(answer)),
        submitEmail: email => dispatch(submitEmail(email))
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Quiz);
