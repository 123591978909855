import {
    SELECT_GENDER, FETCH_DATA_PENDING, FETCH_DATA_SUCCESS, FETCH_DATA_ERROR, SET_GENDER,
    QUIZ, SUBMIT_ANSWER, RESULTS, SUBMIT_EMAIL, GO_BACK
} from '../constants/action-types';

export const initialState = {
    currentPage: SELECT_GENDER,
    currentQuestion: 0,
    pending: false,
    answers: [],
}

export function rootReducer(state = initialState, action) {
    switch(action.type) {
        case FETCH_DATA_PENDING:
            return {
                ...state,
                pending: true
            }
        case FETCH_DATA_SUCCESS:
            return {
                ...state,
                pending: false,
                title: action.title,
                questions: action.questions,
                description: action.description,
                results: action.results,
                logo: action.logo,
                color: action.color,
                background: action.background
            }
        case FETCH_DATA_ERROR:
            return {
                ...state,
                pending: false
            }
        case SET_GENDER:
            return {
                ...state,
                gender: action.gender,
                currentPage: QUIZ
            }
        case GO_BACK:
            if(state.currentQuestion === 0){
                return {
                    ...state,
                    currentPage: SELECT_GENDER
                }
            } else {
                return {
                    ...state,
                    currentQuestion: state.currentQuestion - 1
                }
            }
        case SUBMIT_ANSWER:
            return {
                ...state,
                answers: state.answers.concat([action.answers]),
                currentQuestion: state.currentQuestion + 1
            }
        case SUBMIT_EMAIL:
            return {
                ...state,
                email: action.email,
                currentPage: RESULTS
            }
        default:
            return state;
    }
}

export const getData = state => state;
export const getDataPending = state => state.pending;
export const getDataError = state => state.error;
export const getCurrentPage = state => state.currentPage;
export default rootReducer;
