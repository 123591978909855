import React from 'react';
import { connect } from "react-redux";
import { fetchData } from './actions';
import { getDataError, getCurrentPage, getDataPending } from './reducers';
import {  Quiz, Results } from "./components";
import { SELECT_GENDER, QUIZ, RESULTS } from "./constants/action-types";
import NewQuiz from "./components/NewQuiz";

class App extends React.Component {
	constructor( props ) {
		super( props );
		this.shouldComponentRender = this.shouldComponentRender.bind( this );
	}
	
	// componentDidMount() {
	// 	const { fetchData } = this.props;
	// 	fetchData();
	// }
	
	shouldComponentRender() {
		if ( this.pending === false ) return false;
		// more tests
		return true;
	}
	
	pageSwitcher() {
		const { currentPage } = this.props;
		switch ( currentPage ) {
			case SELECT_GENDER:
				return <NewQuiz />
			case QUIZ:
				return <Quiz />
			case RESULTS:
				return <Results />
			default:
				return <h1>404</h1>;
		}
	}
	
	render() {
		const { error } = this.props;
		if ( !this.shouldComponentRender() ) return <p>Loading...</p>
		return (
			<div>
				{error && <span className="product-list-error">{error}</span>}
				{<NewQuiz />}
			</div>
		)
	}
}

const mapStateToProps = state => ( {
	error: getDataError( state ),
	currentPage: getCurrentPage( state ),
	pending: getDataPending( state )
} )

function mapDispatchToProps( dispatch ) {
	return {
		fetchData: () => dispatch( fetchData() )
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)( App );
