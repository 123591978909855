import React from "react";
import { DFPSlotsProvider, DFPManager, AdSlot } from "react-dfp";
import { Col, Container, Form, Navbar, ProgressBar } from "react-bootstrap";
import { Results } from "./index";
import smoothscroll from 'smoothscroll-polyfill';

class NewQuiz extends React.Component {
	
	constructor( props ) {
		super( props );
		this.state = {
			pending: true,
			answers: [],
			answered: [],
			initialQ: [],
			initialDone: false,
			ans: [],
			emailSubmitRender: false,
			buttonClicked: false
		}
		
		this.handleEmailChange = this.handleEmailChange.bind( this )
		this.handleEmailSubmit = this.handleEmailSubmit.bind( this )
	}
	
	safe_fail() {
		return [{
			"id": "5f2c12b82efe4d0b3e2afb27",
			"title": "Take this beauty quiz and find out which historical era your beauty and aesthetics belong to.",
			"slug": "beauty",
			"image": "https://static.foreshop.net/quiz/images/beauty_logo.png",
			"published": false,
			"color": "#fe1973",
			"background": "https://static.foreshop.net/quiz/images/beauty_bg.jpg",
			"description": "The era your beauty and aesthetics belong to is:",
			"results": ["The Archaic Era", "The Middle Age", "The Elizabethan Era", "The Victorian Era"],
			"questions": [{
				"question": "Pick the mythical creatures you like from this list.",
				"t": "multiple",
				"a": ["Dragon", "Elf", "Vampire", "Unicorn."]
			}, {
				"question": "Which period do you think your aesthetics date back to?",
				"t": "single",
				"a": ["Quite old.", "Ancient.", "Medieval", "Archaic"]
			}, {
				"question": "Choose the gods or goddesses that resonates with you?",
				"t": "multiple",
				"a": ["Thor: God of thunder", "Ares: God of war", "Danu:God of nature", "Lilith: Queen of hell."]
			}, {
				"question": "Which ancient language do you prefer the most?",
				"t": "single",
				"a": ["Greek", "Latin", "Gaelic", "Roman"]
			}, {
				"question": "Select the places you would love to travel to.",
				"t": "multiple",
				"a": ["Egypt", "Scotland", "Greece", "Iceland."]
			}, { "question": "Which animal would you pick from the list?", "t": "single", "a": ["Falcon", "Dog", "Cat", "Horse"] }, {
				"question": "Pick an element.",
				"t": "single",
				"a": ["Water", "Earth", "Fire", "Air"]
			}, { "question": "Pick one.", "t": "single", "a": ["Gold", "Silver", "Bronze", "Diamond"] }, {
				"question": "Choose the show that you are most likely to watch.",
				"t": "Single",
				"a": ["Hercules", "Merlin", "Sharlock", "The Last Kingdom"]
			}, { "question": "Pick the weapon of your choice from this list.", "t": "multiple", "a": ["Sword", "Gun", "Battle axe", "Bow and arrow."] }],
			"month": "august"
		}]
		
	}
	
	load_quiz_data() {
		const quiz = window.quizName
		fetch( `https://quizzes.foreshop.net/api/quizzes/slug/${quiz}`, {
			headers: { 'Content-Type': 'application/json', 'x-api-key': 'AIzaSyBMgXC9PTgSRLzuwYL3K8U5MqxzTwX4w1I' }
		} )
			.then( res => res.json() )
			.then( res => this.setState( { quizData: res[ 0 ] }, () => {
				console.log( this.state.quizData.results.length )
				document.getElementById( 'quiz-root' ).style.backgroundImage = this.state.quizData.background;
			} ) )
			.finally( () => this.setState( { pending: false } ) )
			.catch( () => this.setState( { quizData: this.safe_fail()[ 0 ] } ) )
	}
	
	componentDidMount() {
		this.load_quiz_data();
		this.interval = window.setInterval(
			() => {
				DFPManager.refresh();
			},
			// 35000,
			10000
		);
	}
	
	handleSingleOptionClick( event, j ) {
		return this.setState( { answers: [event.currentTarget.innerText], ans: this.state.ans + j } )
	}
	
	handleMultipleOptionClick( event, j ) {
		return !this.state.answers.includes( event.currentTarget.innerText )
			? this.setState( { answers: this.state.answers.concat( event.currentTarget.innerText ), ans: this.state.ans + j } )
			: this.setState( { answers: this.state.answers.filter( e => e !== event.currentTarget.innerText ) } )
	}
	
	showEmail() {
		this.setState( { emailSubmitRender: true }, () => this.forceUpdate() )
	}
	
	handleEmailChange( e ) {
		this.setState( { email: e.target.value } );
	}
	
	handleEmailSubmit( e ) {
		e.preventDefault();
		let data = {
			'domain': window.location.href.split( "/" )[ 2 ],
			'email': this.state.email,
			'name': 'quiz'
		};
		fetch( 'https://leads.foreshop.net/v1/leads', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			redirect: 'follow',
			referrerPolicy: 'no-referrer',
			body: JSON.stringify( data )
		} ).then( ( response ) => {
			this.setState( { quizDone: true }, () => this.forceUpdate() )
		} )
	}
	
	renderEmail() {
		if ( !this.state.buttonClicked ) {
			return (
				<div id={'qmail'} className="px-4 my-5 py-5">
					<button type="submit" onClick={event => {
						event.preventDefault()
						this.setState( { buttonClicked: true } )
					}}>
						Click Here To View Your Quiz Results
					</button>
				</div>
			);
		} else {
			return (
				<form className="mx-4" onSubmit={this.handleEmailSubmit}>
					<label className="h3 my-4">Leave your email and discover<br />your results!</label>
					<Form.Control
						type="email" required
						onChange={this.handleEmailChange}
						placeholder="enter your email..."
						className="text-center border-0"
					/>
					<button type="submit">Discover my results</button>
				</form>
			)
		}
	}
	
	handleQuestionSubmit( event, index ) {
		event.preventDefault();
		console.log( index + ' to total -> ' + this.state.quizData.questions.length )
		if ( index + 1 === this.state.quizData.questions.length ) {
			this.showEmail();
			setTimeout( () => document.getElementById( `qmail` ).scrollIntoView( { behavior: 'smooth', block: 'start', inline: 'start' } ), 300 )
			
		} else {
			event.preventDefault();
			this.setState( state => {
				state.answered[ index ] = true
			}, () => this.forceUpdate() )
			if ( window.innerWidth > 800 ) {
				setTimeout( () => document.getElementById( `q${index + 1}` ).scrollIntoView( { behavior: 'smooth', block: 'center', inline: 'end' } ), 300 )
			} else {
				setTimeout( () => document.getElementById( `q${index + 1}` ).scrollIntoView( { behavior: 'smooth', block: "center", inline: 'center' } ), 300 )
			}
		}
	}
	
	buttonDisabled( id ) {
		let current = document.getElementById( id );
		console.log( current )
	}
	
	renderQuestions( question ) {
		const { color } = this.state.quizData
		const small = window.innerWidth > 800;
		return (
			<div>
				{question.map( ( item, j ) => {
					return (
						( j === 0 ? true : !!this.state.answered[ j - 1 ] ) &&
						<div style={{ display: 'flex', justifyContent: 'flex-end' }} className={'q-container'}>
							{window.innerWidth > 850 && <Col style={{ display: 'flex', justifyContent: 'flex-end' }} xs={4}>
								<AdSlot adUnit={window.quizC} sizes={[[250, 250], [336, 280], [320, 50], [320, 100], [200, 200], 'fluid', [390, 100], [300, 250]]} />
							</Col>}
							<Col lg={4} md={6} xs={12} className={'text-center'}>
								<div className="quiz">
									
									<style>{`
                                :root {
                                  --main-color: ${color};
                                }
                            `}</style>
									{!this.state.answered[ j ] &&
									<ProgressBar now={( ( j / this.state.quizData.questions.length ) * 100 ).toFixed( 0 )} striped animated variant="custom" />}
									<Container id={'q' + j} style={{ paddingBottom: '20px' }}>
										<h3 style={{ margin: '0px', fontSize: `${window.innerWidth < 800 ? 'large' : ''}` }} className="px-2 pb-2 pt-2">{item.question}</h3>
										<form className="px-2">
											{
												item.a.map( ( e, i ) => {
													return (
														<div
															style={{
																padding: small ? '13px 40px' : '8px 40px',
																marginBottom: '5px',
																fontWeight: 'bold',
																fontSize: window.innerWidth < 800 ? 'small' : ''
															}}
															className={this.state.answers.includes( e ) ? "selected" : ""}
															key={e + i}
															onClick={event => question.t === "single" ? this.handleSingleOptionClick( event, j ) : this.handleMultipleOptionClick( event, j )}
														>
															{e}
														</div>
													)
												} )
											}
											<button style={{ marginTop: '15px' }} type={"submit"} onClick={event => this.handleQuestionSubmit( event, j )}
											        disabled={!this.state.ans.includes( j )}>
												{`Next`}
											</button>
										</form>
									</Container>
								</div>
								{j !== this.state.quizData.questions.length - 1 && <div style={{ marginBlockStart: '10px', height: window.innerWidth > 770 ? '300px' : '200px' }}>
									{window.innerWidth < 850 && <AdSlot adUnit={window.quizC} sizes={[[320, 50], [320, 100], [200, 200]]} />}
								</div>}
								{this.state.emailSubmitRender && j === this.state.quizData.questions.length - 1 &&
								
								this.renderEmail()
								
								}
							</Col>
							{window.innerWidth > 770 && <Col xs={4}>
								<AdSlot adUnit={window.quizC} sizes={[[250, 250], [336, 280], [320, 50], [320, 100], [200, 200], 'fluid', [390, 100], [300, 250]]} />
							</Col>}
						</div>
					
					)
				} )}
			
			</div>
		
		);
	}
	
	render() {
		const { pending, quizDone } = this.state;
		smoothscroll.polyfill();
		if ( pending ) {
			return (
				<div>Loading...</div>
			)
		} else if ( quizDone ) {
			return (
				<Results data1={this.state.quizData} />
			)
		} else {
			return (
				<div style={{ marginBlockEnd: '55px', marginBlockStart: '200px' }}>
					{this.state.quizData && !this.state.initialDone &&
					//style={window.innerWidth > 767? {backgroundImage: `url('${background}')`}:{}}>
					<div className={'content'} style={{
						height: '90vh',
						textAlign: '-webkit-center',
						backgroundImage: `url('${this.state.quizData.background || ''}')`,
						backgroundRepeat: 'no-repeat',
						backgroundPositionX: `${window.innerWidth > 800 ? '50%' : '100%'}`
					}}>
						<Col lg={4} md={6} xs={12}>
							<style>{`
                                :root {
                                  --main-color: ${this.state.quizData.color};
                                }
                            `}</style>
							<Container style={{ justifyContent: "center", alignItems: 'center', marginBottom: '5rem' }}>
								<h3 className="px-4 pb-2 pt-4">{this.state.quizData.title}</h3>
								<form className="px-2">
									<button type="submit" onClick={() => this.setState( { initialDone: true }, () => {
										this.forceUpdate();
										setTimeout(() =>document.getElementById( 'q0' ).scrollIntoView( { behavior: 'smooth', block: 'center' } ),1000)
									} )}>Start Quiz!
									</button>
								</form>
							</Container>
						</Col>
					</div>}
					<DFPSlotsProvider dfpNetworkId="21863165165">
						<div style={{ overscrollBehavior: 'smooth' }}>
							<div style={{ height: '200px' }}>
								{window.innerWidth < 850 && <AdSlot adUnit={window.quizC} sizes={[[320, 50], [320, 100], [390, 100]]} />}
							</div>
							{this.state.quizData && this.state.initialDone && this.renderQuestions( this.state.quizData.questions )}
						</div>
					</DFPSlotsProvider>
				</div>
			)
		}
		
	}
}

export default NewQuiz;
