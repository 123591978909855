import { Col } from "react-bootstrap";
import { AdSlot } from "react-dfp";
import React from "react";

export function renderAd( adType ) {
	
	const adClass = adType === 'rightAd' && window.innerWidth < 768 ? 'text-center sticky-ad' : 'text-center';
	const handleMobile = window.innerWidth < 768 ? {position: 'fixed'} : {position: 'inherit'};
	
	
	const adSize =
		window.innerWidth > 1199 ? [[300, 600], [300, 250], [160, 600], [120, 600]]
			: window.innerWidth > 767 ? [[160, 600], [120, 600]]
			: adType === 'leftAd' ? [[320, 100], [320, 50]] : [[320, 100]];
	
	return (
		<Col sm={12} md={3} lg={4}>
			<AdSlot style={handleMobile} adUnit={window.adSide} sizes={adSize} className={adClass} />
		</Col>
	)
}





