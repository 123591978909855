import {
	FETCH_DATA_PENDING, FETCH_DATA_SUCCESS, FETCH_DATA_ERROR, SET_GENDER, GO_BACK,
	SUBMIT_ANSWER, SUBMIT_EMAIL
} from "../constants/action-types";

function fetchPending() {
	return {
		type: FETCH_DATA_PENDING
	}
}

function fetchSuccess( data ) {
	return {
		type: FETCH_DATA_SUCCESS,
		title: data.title,
		questions: data.questions,
		description: data.description,
		logo: data.image,
		color: data.color,
		background: data.background,
		results: data.results
	}
}

function fetchError() {
	return {
		type: FETCH_DATA_ERROR
	}
}

export function fetchData() {
	return dispatch => {
		dispatch( fetchPending() );
		fetch( `https://quizzes.foreshop.net/api/quizzes/slug/beauty`, {
			headers: { 'Content-Type': 'application/json', 'x-api-key': 'AIzaSyBMgXC9PTgSRLzuwYL3K8U5MqxzTwX4w1I' }
		} )
			.then( res => res.json() )
			.then( res => {
				if ( res.error ) {
					throw( res.error );
				}
				dispatch( fetchSuccess( res[ 0 ] ) );
				return res;
			} )
			.catch( err => {
				dispatch( fetchError() )
			} );
	}
}

export function selectGender( gender ) {
	return {
		type: SET_GENDER,
		gender: gender
	}
}

export function goBack() {
	return {
		type: GO_BACK
	}
}

export function submitAnswer( answer ) {
	return {
		type: SUBMIT_ANSWER,
		answer: answer
	}
}

export function submitEmail( email ) {
	return {
		type: SUBMIT_EMAIL,
		email: email
	}
}
