import React from "react";
import {connect} from "react-redux";
import {Button, Col, Row, Container} from "react-bootstrap";
import mArrow from "../assets/images/m-arrow.png";
import mIcon from "../assets/images/m-icon.png";
import wArrow from "../assets/images/w-arrow.png";
import wIcon from "../assets/images/w-icon.png";
import {selectGender} from "../actions";
import {getData} from '../reducers';


class SelectGender extends React.Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit = (gender) => {
        this.props.selectGender(gender);
    }

    render() {
        const { logo, title, color, background } = this.props.data;
        return (
            <Container id="select-gender" style={window.innerWidth > 767? {backgroundImage: `url('${background}')`}:{}}>
                <Row>
                <Col>
                    <img src={logo} alt={title} width="200" className="my-5"/>
                    <h1 className="my-5" style={{color: color}}>{title}</h1>
                    <h4 className="mb-4">Select your gender:</h4>
                    <div className="d-flex flex-wrap">
                        <Button className="f-btn mr-3" onClick={() => this.handleSubmit('w')}>
                            <img src={wIcon} alt="w-icon" width="22" height="29"/>
                            Women click here
                            <img src={wArrow} alt="arrow-right"/>
                        </Button>
                        <Button className="m-btn" onClick={() => this.handleSubmit('m')}>
                            <img src={mIcon} alt="m-icon" width="22" height="29"/>
                            Men click here
                            <img src={mArrow} alt="arrow-right"/>
                        </Button>
                    </div>
                    <p className="my-5">2020 © ALL RIGHTS RESERVED</p>
                </Col>
                </Row>
            </Container>
        );
    }
}

const mapStateToProps = state => ({
    data: getData(state)
})

function mapDispatchToProps(dispatch) {
    return {
        selectGender: gender => dispatch(selectGender(gender))
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SelectGender);
