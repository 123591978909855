import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import { rootReducer } from "../reducers/index";

export const loadState = () => {
    try {
        const serializedState = sessionStorage.getItem('state');

        if (serializedState === null) {
            return undefined;
        }

        return JSON.parse(serializedState);
    } catch (error) {
        return undefined;
    }
};

export const saveState = (state) => {
    try {
        const serializedState = JSON.stringify(state);
        sessionStorage.setItem('state', serializedState);
    } catch (error) {
        // Ignore write errors.
    }
};

const persistedState = loadState();
const middleware = [thunk];

const store = createStore(rootReducer, persistedState, applyMiddleware(...middleware));
store.subscribe(() => {
    saveState(store.getState());
});

export default store;








