import React from "react";
import { connect } from "react-redux";
import { getData } from "../reducers";
import { Button, Container, Row, Col } from "react-bootstrap";

class Results extends React.Component {
	
	componentDidMount() {
		document.getElementById( 'results' ).scrollIntoView( { behavior: 'smooth', block: 'start', inline: 'start' } );
		document.body.style.background = this.props.data1.background;
		
	}
	
	render() {
		const { logo, title, color, description, results } = this.props.data1;
		return (
			<Container id="results">
				<Row>
					<Col xs={12} style={{textAlign: '-webkit-center'}}>
						<img src={logo} alt={title} width="200" className="my-5" />
						<h4>{description}</h4>
						<h1 style={{ color: color }}>
							{results[ Math.floor( Math.random() * ( results.length ) ) ]}
						</h1>
						<br /><br />
						<div className="related-post">
							<a href={"https://cupofc.com/click.php?key=h9tv39y804duuni4ouie"} target={"_blank"} style={{ width: '500px' }}>
								<img src="https://quiz.foreshop.net/quiz-images/Winaniphone.jpeg" style={{ width: '500px' }} />
								<center><h6>You Can WIN an iPhone RIGHT NOW!</h6></center>
								<center><Button size="sm" style={{ backgroundColor: color, borderColor: color, color: 'white' }}>Claim Reward</Button></center>
							</a>
						</div>
						<h4 className={"text-center"}>You also might like to read those:</h4>
						<div className="related-post">
							{window.recentPosts && window.recentPosts.map( e => {
								return (
									<a href={e.link} title={e.title} style={window.innerWidth < 470 ? { width: 100 + "%" } : {}}>
										<img src={e.image} alt={e.title} />
										<h6>{e.title}</h6>
										<Button size="sm" style={{ backgroundColor: color, borderColor: color }}>Read More</Button>
									</a>
								)
							} )}
						</div>
						<p className="text-center">2020 © ALL RIGHTS RESERVED</p>
					</Col>
				</Row>
			</Container>
		);
	}
}

const mapStateToProps = state => ( {
	data: getData( state )
} )

export default connect(
	mapStateToProps,
	null
)( Results );

